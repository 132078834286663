/*eslint-disable*/
import React from "react";
import { io } from "socket.io-client";
import { find } from "lodash";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { connectAutoDispatch } from "redux/reduxConnect";
import {
  getProductPlanRequest,
  getCurrentPluginsRequest,
  getCurrentPluginsAddedRequest,
  userBuyPluginsRequest
} from "redux/actions/PlanActions";
import { getUserInfoAppRequest } from "redux/actions/UserActions";
import { stripeGetPricesRequest } from "redux/actions/StripeActions";
import { userInitState, userPropTypes } from "redux/reducers/UserReducer";
import { planInitState, planPropTypes } from "redux/reducers/PlanReducer";
import { withSnackbar } from "notistack";

import PlanUICommon from "components/plans/asin-tracker";
import { findIndex, groupBy } from "lodash";
import { withRouter, Link } from "react-router-dom";
import { compose } from "redux";
import isEqual from "lodash/isEqual";

import { apiGetCheckoutUrl, apiGetPortalUrl, apiUpdateSubscription } from "api";
import AZInshgitIcon from "static/img/azinsight-scanez.png";
import BulkCheckerIcon from "static/img/bulkchecker.png";
import AZAlertIcon from "static/img/azalert.png";
import constants from "utils/constants";
import { ListProductStyles } from "./ListProduct.style";
import ShowPlugins from "./ShowPlugins";
import AZAlertSubAccounts from "./AZAlertSubAccounts";
import AZInsightPlan from "./AZInsightPlan";
import AZAlertPlan from "./AZAlertPlan";
import AZInsightSubAccounts from "./AZInsightSubAccounts";

class ListProduct extends React.Component {
  io = null;

  constructor(props) {
    super(props);

    const { location } = props;
    const query = new URLSearchParams(location.search);
    const queryTab = query.get("tab") ? query.get("tab").toLowerCase() : null;
    const queryMenu = query.get("menu") ? query.get("menu").toLowerCase() : null;

    /**  init tab */
    let planTab = "";
    switch (queryTab) {
      case "azalert":
        planTab = "AZALERT";
        break;
      default:
        planTab = "AZINSIGHT";
        break;
    }

    this.intervalLables = {
      year: "Annually",
      month: "Monthly"
    };

    this.tabs = ["AZINSIGHT", "AZALERT"];

    this.features = {
      AZINSIGHT: [
        {
          name: "2-sub accounts",
          plans: ["Starter Plan", "Advanced Plan"]
        },
        {
          name: "Seller Restriction Checker",
          plans: ["Starter Plan", "Advanced Plan"]
        },
        {
          name: "Hazmat Checker",
          plans: ["Starter Plan", "Advanced Plan"]
        },
        {
          name: "Brand Notification Alert",
          plans: ["Starter Plan", "Advanced Plan"]
        },
        {
          name: "Profit FBM & FBA Calculator",
          plans: ["Advanced Plan"]
        },
        {
          name: "Stock Checker",
          plans: ["Advanced Plan"]
        },
        {
          name: "Variations Viewer",
          plans: ["Advanced Plan"]
        },
        {
          name: "Sales + Revenue Estimations",
          plans: ["Advanced Plan"]
        },
        {
          name: "All Plugins",
          plans: ["Advanced Plan"]
        },
        {
          name: "ScanEZ mobile app",
          plans: ["Advanced Plan"]
        }
      ],
      BULKSCANPRO: [],
      AZALERT: []
    };

    /** init drawer */
    this.drawerItems = [
      {
        text: "Plans",
        key: "PLAN"
      },
      {
        text: "Sub-accounts",
        key: "SUB_ACCOUNTS"
      }
    ];
    let drawer = this.drawerItems[0].key;
    if (queryMenu === "sub") {
      drawer = this.drawerItems[1].key;
    }

    this.titlePlanAzinsight = {
      HAZability_PLAN_MONTHLY: "Starter Plan",
      HAZability_PLAN: "Starter Plan",
      AZInsight_PLAN_MONTHLY: "Advanced Plan",
      AZInsight_PLAN: "Advanced Plan"
    };

    this.state = {
      planTab,
      tabIndex: this.tabs.indexOf(planTab),
      interval: "Year",
      drawer,
      checkoutUrl: "",
      openCouponCodeWarning: false,
      invalidCouponCodes: [],
      disabled: false,
      openRenewConfirmation: false,
      openRenewConfirmation2: false,
      selectedPlan: null,
      openPopupMws1: false,
      openPopupMws2: false,
      openErrorDialog: false,
      loading: false,
      mobileOpen: false
    };
    this.props.getProductPlanRequest();
    this.props.stripeGetPricesRequest();
    this.props.getCurrentPluginsRequest();
    this.props.getCurrentPluginsAddedRequest();
  }

  reloadPage = () => {
    window.location.href = this.getReturnUrl();
  };

  connetSocket = () => {
    const accessToken = localStorage.getItem(constants.KEY_LOCAL_TOKEN);
    this.io = io(process.env.AZ_SOCKET_URL, {
      query: `accessToken=${accessToken}`
    });
    this.io.on("message", payload => {
      console.log(payload);
    });
    this.io.on("subscription:updated", payload => {
      this.reloadPage();
    });

    this.io.on("subscription:deleted", payload => {
      this.reloadPage();
    });
  };

  planTabChange = (e, index) => {
    this.setState({
      tabIndex: index,
      planTab: this.tabs[index],
      interval: "Year",
      drawer: "PLAN"
    });
  };

  changeDrawer = drawer => {
    this.setState({
      drawer
    });
  };

  changeInterval = interval => {
    this.setState({
      interval
    });
  };

  closeCouponCodeWarning = () => {
    this.setState({
      openCouponCodeWarning: false,
      invalidCouponCodes: [],
      checkoutUrl: "",
      disabled: false
    });
  };

  openRenewConfirmation = (e, selectedPlan) => {
    e && e.preventDefault();
    if (selectedPlan.cancel) {
      this.updateSubscription(selectedPlan);
    } else {
      this.setState({
        openRenewConfirmation: true,
        selectedPlan
      });
    }
  };

  closeRenewConfirmation = () => {
    this.setState({
      openRenewConfirmation: false,
      selectedPlan: null
    });
  };

  closeRenewConfirmation2 = () => {
    const {
      userState: { userInfo }
    } = this.props;
    localStorage.setItem(constants.KEY_LOCAL_PLANS, userInfo.stripeSubId);
    this.setState({
      openRenewConfirmation2: false,
      selectedPlan: null
    });
  };

  comfirmRenew = () => {
    const { selectedPlan } = this.state;
    this.closeRenewConfirmation();
    this.updateSubscription({
      ...selectedPlan
    });
  };

  comfirmRenew2 = () => {
    const {
      userState: { userInfo }
    } = this.props;
    this.updateSubscription({
      subId: userInfo.stripeSubId,
      cancel: !userInfo.isCanceledAccount,
      reason: "",
      title: this.titlePlanAzinsight[userInfo.stripePriceKey]
    });
    this.closeRenewConfirmation2();
  };

  openPopupMws1 = () => {
    this.setState({
      openPopupMws1: true,
      loading: false
    });
  };

  openPopupMws2 = () => {
    this.setState({
      openPopupMws2: true
    });
  };

  closePopupMws1 = () => {
    this.setState({
      openPopupMws1: false
    });
  };

  closePopupMws2 = () => {
    this.setState({
      openPopupMws2: false
    });
  };

  updateSubscription = async data => {
    this.setState({
      loading: true
    });
    try {
      await apiUpdateSubscription(data);
    } catch (e) {
      if (e.response && e.response.status === 500) {
        this.openErrorDialog();
      }
      this.setState({
        loading: false
      });
    }
  };

  openUrl = url => {
    window.location.href = url;
  };

  getReturnUrl = () => {
    const { tabIndex, drawer } = this.state;
    const tab = this.tabs[tabIndex];
    const menu = drawer === "SUB_ACCOUNTS" ? "sub" : "";
    return `${window.location.origin}${
      window.location.pathname
    }?tab=${tab.toLowerCase()}&menu=${menu}`;
  };

  openStripePortal = async () => {
    try {
      const resp = await apiGetPortalUrl({
        returnUrl: this.getReturnUrl()
      });
      if (resp.url) {
        window.location.href = resp.url;
      }
    } catch (e) {
      if (e.response && e.response.status === 500) {
        this.openErrorDialog();
      }
    }
  };

  openCheckoutUrl = async item => {
    try {
      this.setState({
        loading: true
      });
      const resp = await apiGetCheckoutUrl({
        price: item.priceId,
        mode: item.mode,
        quantity: 1,
        returnUrl: this.getReturnUrl()
      });

      if (resp.status === "SUBSCRIPTION_CHANGED") {
        return;
      }

      if (resp.status === "NO_MWS") {
        this.openPopupMws1();
        return;
      }

      if (resp.invalidCouponCodes && resp.invalidCouponCodes.length) {
        this.setState({
          invalidCouponCodes: resp.invalidCouponCodes,
          openCouponCodeWarning: true,
          checkoutUrl: resp.url,
          loading: false
        });
      } else {
        this.openUrl(resp.url);
      }
    } catch (e) {
      console.log(e);
      if (e.response && e.response.status === 500) {
        this.openErrorDialog();
      }
      this.closeLoading();
    }
  };

  checkUserBuyPlan = (item, listUserPlan = []) => {
    for (let i = 0; i < listUserPlan.length; i++) {
      if (item._id === listUserPlan[i]._id) {
        return { isActive: listUserPlan[i].isActive, isCancel: listUserPlan[i].isCancel };
      }
    }
    return {};
  };

  renderBulkCheckerPlan = () => {
    const { gXs, gSm } = this.state;
    const {
      userState,
      planState: {
        bulkCheckerPlans: { data }
      }
    } = this.props;
    const { listUserPlan } = userState.userInfo;
    return data
      .map(item => {
        return { ...item, ...this.checkUserBuyPlan(item, listUserPlan) };
      })
      .map((d, k) => (
        <PlanUICommon
          gXs={gXs}
          gSm={gSm}
          handleShowSnackBar={this.handleShowSnackBar}
          key={k}
          currentUserData={userState.userInfo}
          detectIsExtraLogin={this.detectIsExtraLogin()}
          showAlert={this.showAlert}
          item={d}
          k={k}
        />
      ));
  };

  getPlugins = (plugins, showAZAlert) => {
    const {
      userState: { userInfo },
      planState: { pluginsAdded }
    } = this.props;
    if (!plugins || !plugins.length) return [];
    return plugins.filter(d => {
      if (showAZAlert) {
        if (d.code === "AZALERT_EXTRA_LOGIN") {
          return true;
        } else {
          return false;
        }
      } else if (
        (["hazmat", "basic"].includes(userInfo.productType) && d.code === "AZIPALERT") ||
        d.code === "AZALERT_EXTRA_LOGIN"
      ) {
        return false;
      }
      return true;
    });
  };

  componentDidMount() {
    // window.analytics.page("ManageAddOns");
    this.connetSocket();
    const {
      userState: { userInfo }
    } = this.props;
    if (
      userInfo.stripeSubId &&
      !isEqual(localStorage.getItem(constants.KEY_LOCAL_PLANS), userInfo.stripeSubId)
    ) {
      this.setState({ openRenewConfirmation2: true });
    }
  }

  componentWillUnmount() {
    if (this.io) {
      this.io.emit("forceDisconnect");
      this.io = null;
    }
  }

  componentDidUpdate(prevProps) {
    const {
      planState,
      userState: { userInfo }
    } = this.props;
    if (!isEqual(userInfo.stripeSubId, prevProps.userState.userInfo.stripeSubId)) {
      if (
        userInfo.stripeSubId &&
        !isEqual(localStorage.getItem(constants.KEY_LOCAL_PLANS), userInfo.stripeSubId)
      ) {
        this.setState({ openRenewConfirmation2: true });
      } else {
        this.setState({ openRenewConfirmation2: false });
      }
    }
    if (
      prevProps.planState.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      !planState.errorMessage.code
    ) {
      this.props.enqueueSnackbar(
        "Thank you! Please refresh your browser for the new plugin to show up",
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          autoHideDuration: 5000
        }
      );
    }

    if (
      prevProps.planState.callingUserBuyPlugin &&
      !planState.callingUserBuyPlugin &&
      planState.errorMessage.code
    ) {
      this.props.enqueueSnackbar(planState.errorMessage.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        autoHideDuration: 5000
      });
    }

    if (
      planState.callingGetListPlan !== prevProps.planState.callingGetListPlan &&
      !planState.callingGetListPlan &&
      this.queryPlugin
    ) {
      setTimeout(() => {
        const div = document.getElementById("AZ-Plugin");
        div.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  }

  cancelSubscription = () => {
    const {
      userState: { userInfo }
    } = this.props;
    document.getElementById(`cancel-sub-${userInfo.stripePriceKey}`).click();
  };

  openZoho = e => {
    e && e.preventDefault();
    window.ZohoHCAsap && window.ZohoHCAsap.Action("open");
  };

  openZohoArticle = (e, articleId) => {
    window.ZohoHCAsap && window.ZohoHCAsap.kbArticles.view({ articleId });
  };

  closeLoading = () => {
    this.setState({ loading: false });
  };

  openErrorDialog = () => {
    this.setState({
      openErrorDialog: true
    });
  };

  closeErrorDialog = support => {
    this.setState({
      openErrorDialog: false
    });
    if (support) {
      this.openZoho();
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  render() {
    const {
      planTab,
      tabIndex,
      drawer,
      openCouponCodeWarning,
      invalidCouponCodes,
      checkoutUrl,
      openRenewConfirmation,
      openRenewConfirmation2,
      openPopupMws1,
      openPopupMws2,
      loading,
      openErrorDialog,
      interval,
      mobileOpen
    } = this.state;

    const {
      classes,
      planState,
      userState: { userInfo },
      stripeState
    } = this.props;
    const {
      currentPlugins,
      asinTrackerPlugins,
      currentPlans,
      bcpPlugins,
      pluginsAdded
    } = planState;
    let plugins = this.getPlugins(currentPlugins.data);
    let azAlertPlugins = this.getPlugins(currentPlugins.data, true);
    let paidPlugins = plugins.filter(item => item.price);
    paidPlugins = groupBy(paidPlugins, "parent");
    paidPlugins.ASIN_TRACKER = asinTrackerPlugins.data.filter(item => item.price);
    paidPlugins.BULK_CHECKER_PRO =
      (userInfo &&
        userInfo.listUserPlan &&
        findIndex(userInfo.listUserPlan, { id: "BULK_CHECKER_PRO" }) >= 0 &&
        bcpPlugins.data.filter(item => item.price)) ||
      [];

    if (!stripeState || !stripeState.prices.loaded) {
      return null;
    }

    let azinsightContent = null;
    let azAlertContent = null;
    const SUB_ACOUNTS_PLUGINS = [
      "AZINSIGHT_PRO_ADDITIONAL_LOGIN",
      "AZINSIGHT_PRO_ADDITIONAL_LOGIN_MONTHLY",
      "AZINSIGHT_PLUS_ADDITIONAL_LOGIN",
      "AZINSIGHT_PLUS_ADDITIONAL_LOGIN_MONTHLY"
    ];
    const AZINSIGHT_PLUGINS_EXCLUDES = [...SUB_ACOUNTS_PLUGINS, "AZIPALERT"];

    /** AZALERT PLANS */
    let azAlertPlan = find(currentPlugins.data, { code: "AZIPALERT" });
    const price = stripeState.prices.data.find(
      price => azAlertPlan && price.metadata && price.metadata.key === azAlertPlan.code
    );
    if (price) {
      let subscribed = false;
      if (pluginsAdded) {
        subscribed = !!pluginsAdded.find(item => item.pluginId._id === azAlertPlan._id);
      }
      azAlertPlan = {
        ...azAlertPlan,
        canSubscribe: !subscribed,
        subscribed,
        priceId: price.id,
        key: price.metadata.key,
        amount: price.unit_amount / 100,
        mode: price.type === "recurring" ? "subscription" : "payment"
      };
    } else {
      azAlertPlan = null;
    }
    /** AZALERT PLANS */

    switch (drawer) {
      case "PLAN":
        azinsightContent = (
          <AZInsightPlan
            data={currentPlans.data}
            prices={stripeState.prices}
            classes={classes}
            interval={interval}
            planTab={planTab}
            userInfo={userInfo}
            changeInterval={this.changeInterval}
            intervalLables={this.intervalLables}
            features={this.features}
            openRenewConfirmation={this.openRenewConfirmation}
            openCheckoutUrl={this.openCheckoutUrl}
            openErrorDialog={this.openErrorDialog}
          />
        );
        azAlertContent = <AZAlertPlan plan={azAlertPlan} openCheckoutUrl={this.openCheckoutUrl} />;
        break;
      case "SUB_ACCOUNTS":
        const pluginsAZInsightSubAccount = paidPlugins.AZINSIGHT
          ? paidPlugins.AZINSIGHT.reduce((result, item) => {
              const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
              if (plugin && SUB_ACOUNTS_PLUGINS.includes(item.code)) {
                result.push({
                  priceId: plugin.id,
                  ...item,
                  ...plugin
                });
              }
              return result;
            }, [])
          : [];
        const pluginsAZAlertSubAccount = azAlertPlugins.reduce((result, item) => {
          const plugin = stripeState.prices.data.find(p => p.metadata.key === item.code);
          if (plugin) {
            result.push({
              priceId: plugin.id,
              ...item,
              ...plugin
            });
          }
          return result;
        }, []);
        azinsightContent = (
          <AZInsightSubAccounts
            plugins={pluginsAZInsightSubAccount}
            userInfo={userInfo}
            data={currentPlans.data}
            pluginsAdded={pluginsAdded}
            classes={classes}
            intervalLables={this.intervalLables}
            openZohoArticle={this.openZohoArticle}
            openRenewConfirmation={this.openRenewConfirmation}
            openStripePortal={this.openStripePortal}
            openCheckoutUrl={this.openCheckoutUrl}
          />
        );
        azAlertContent = (
          <AZAlertSubAccounts
            plan={azAlertPlan}
            plugins={pluginsAZAlertSubAccount}
            userInfo={userInfo}
            classes={classes}
            openZohoArticle={this.openZohoArticle}
            openCheckoutUrl={this.openCheckoutUrl}
          />
        );
        break;
      case "PLUGINS":
        const pluginsShow = paidPlugins.AZINSIGHT
          ? paidPlugins.AZINSIGHT.filter(item => !AZINSIGHT_PLUGINS_EXCLUDES.includes(item.code))
          : [];
        azinsightContent = (
          <ShowPlugins
            plans={currentPlans}
            plugins={pluginsShow}
            prices={stripeState.prices}
            classes={classes}
            userBuyPluginsRequest={this.props.userBuyPluginsRequest}
            handleShowSnackBar={this.handleShowSnackBar}
          />
        );
        break;
    }

    let subInfo = null;
    if (userInfo.stripeSubId) {
      if (userInfo.isCanceledAccount === true && moment().isBefore(userInfo.nextSubscribeAt)) {
        subInfo = (
          <>
            <div>
              Your subscription is active until{" "}
              <strong>{moment(userInfo.nextSubscribeAt).format("MMMM DD, YYYY")}</strong> and will
              <strong> NOT</strong> auto-renew.
            </div>
            <div>
              If you wish to automatically renew your subscription, please click{" "}
              <a
                href="#"
                onClick={e =>
                  this.openRenewConfirmation(e, {
                    subId: userInfo.stripeSubId,
                    cancel: false
                  })
                }
              >
                here.
              </a>
            </div>
          </>
        );
      }

      if (!userInfo.isCanceledAccount) {
        subInfo = (
          <>
            <div>
              Your subscription is active until{" "}
              <strong>{moment(userInfo.nextSubscribeAt).format("MMMM DD, YYYY")}</strong> and will
              auto-renew.
            </div>
            <div>
              If you wish to cancel auto-renew on your subscription, please click{" "}
              <a href="#" onClick={this.cancelSubscription}>
                here.
              </a>
            </div>
          </>
        );
      }
    }

    return (
      <div className={classes.root}>
        <Paper
          square
          classes={{
            root: classes.appBar
          }}
        >
          <Tabs value={tabIndex} onChange={this.planTabChange} centered>
            <Tab
              icon={<img className={classes.tabIcon} style={{ width: 58 }} src={AZInshgitIcon} />}
              label="AZInsight & ScanEZ"
            />
            <Tab icon={<img className={classes.tabIcon} src={AZAlertIcon} />} label="AZAlert" />
          </Tabs>
        </Paper>
        <Button
          color="primary"
          variant="outlined"
          onClick={this.handleDrawerToggle}
          className={classes.menuButton}
        >
          Pricing Plans
          <Menu />
        </Button>
        <div className={classes.root2}>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              root: classes.drawerPaperMobileRoot,
              paper: classes.drawerPaperMobile
            }}
            ModalProps={{
              keepMounted: true
            }}
          >
            <div className={classes.drawerContainer}>
              <List>
                <Divider />
                {this.drawerItems.map(item => (
                  <>
                    <ListItem
                      key={item.key}
                      button
                      selected={drawer === item.key}
                      onClick={() => this.changeDrawer(item.key)}
                    >
                      <ListItemText primary={item.text} />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </div>
          </Drawer>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              root: classes.drawerPaperRoot,
              paper: classes.drawerPaper
            }}
            open
          >
            <div className={classes.drawerContainer}>
              <List>
                <Divider />
                {this.drawerItems.map(item => (
                  <>
                    <ListItem
                      key={item.key}
                      button
                      selected={drawer === item.key}
                      onClick={() => this.changeDrawer(item.key)}
                    >
                      <ListItemText primary={item.text} />
                    </ListItem>
                    <Divider />
                  </>
                ))}
              </List>
            </div>
          </Drawer>
          <div className={classes.content}>
            <Grid className={`${planTab !== "AZINSIGHT" ? "hidden" : ""}`}>
              {drawer === "PLAN" && subInfo ? (
                <Grid style={{ fontSize: 16, textAlign: "center", marginBottom: 20 }}>
                  {subInfo}
                </Grid>
              ) : null}
              {azinsightContent}
            </Grid>
            <Grid className={`${planTab !== "AZALERT" ? "hidden" : ""}`}>{azAlertContent}</Grid>
          </div>
        </div>
        <Dialog
          open={openCouponCodeWarning}
          fullWidth
          maxWidth="sm"
          onClose={this.closeCouponCodeWarning}
        >
          <DialogTitle disableTypography>
            <strong>Warning</strong>
          </DialogTitle>
          <DialogContent>
            This coupon code <strong>{`${invalidCouponCodes.join(",")}`}</strong> is no longer valid
            or has expired or does not apply to the plan you've selected. If you need assistant,{" "}
            <a href="#" onClick={this.openZoho}>
              contact support
            </a>
            .
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeCouponCodeWarning}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={() => this.openUrl(checkoutUrl)}>
              Checkout
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openRenewConfirmation}
          fullWidth
          maxWidth="sm"
          onClose={this.closeRenewConfirmation}
        >
          <DialogTitle disableTypography>
            <strong>Automatic renewal of your subscription fees</strong>
          </DialogTitle>
          <DialogContent>
            By enrolling in the auto-renew program, you authorize asinzen to automatically charge
            your subscription fees to your credit card on file on the end of the billing cycle date
            and agree that you are the owner or authorized user of the accounts and payment method
            to be charged. By clicking the Agree button below, you also agree to the{" "}
            <a href="https://help.asinzen.com/autorenew" target="_blank">
              auto renew terms and conditions
            </a>
            .
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeRenewConfirmation}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={this.comfirmRenew}>
              Enable Auto Renew
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openRenewConfirmation2}
          fullWidth
          maxWidth="sm"
          onClose={this.closeRenewConfirmation2}
        >
          <DialogTitle disableTypography>
            <strong>Do you want to turn on auto-renew of your subscription?</strong>
          </DialogTitle>
          <DialogContent>
            <p>
              Auto renew is turned OFF by default which will require you to manually renew your
              subscription by the end of the billing cycle date. If you wish to have asinzen
              automatically renew your subscription, click the Enable Auto Renew button below. You
              can turn the auto-renew feature OFF at any time from the Plans page.
            </p>
            <br />
            <p>
              By enrolling in the auto-renew program, you authorize asinzen to automatically charge
              your subscription fees to your credit card on file on your subscription's renewal
              date. You agree that you are the owner or authorized user of the account and payment
              method to be charged. By clicking the Enable Auto Renew button below, you also agree
              to the{" "}
              <a href="https://help.asinzen.com/autorenew" target="_blank">
                auto renew terms and conditions
              </a>
              .
            </p>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeRenewConfirmation2}>
              No thanks
            </Button>
            <Button variant="contained" color="primary" onClick={this.comfirmRenew2}>
              Enable Auto Renew
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPopupMws1} fullWidth maxWidth="md" onClose={this.closePopupMws1}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                Our tools depend on the Amazon integration to give you the newest and most accurate
                data about an ASIN you are researching. At this time only sellers with an Amazon
                Professional seller account can complete the Amazon integration, Individual seller
                accounts can not.
              </Grid>
              <Grid item xs={12}>
                If you do not have an Amazon Professional seller account, we have to use a different
                process to provide you service which is only available on the Monthly subscription
                plan. You can switch from the monthly to the annual subscription plan at any point
                after you upgrade your Amazon Seller account and complete the Amazon integration.
              </Grid>
              <Grid item xs={12}>
                If you have an Amazon Professional account select the "Complete the Amazon
                integration" option below to proceed. 
                If you do not have a Professional seller
                account, then click "I don't have an Amazon Pro seller account" and select the
                Monthly plan to subscribe.
              </Grid>
              <Grid item xs={12}>
                Unsure or need help to determine if your Amazon seller account is a Professional
                account, click{" "}
                {/* <a href="AZ-href" onClick={e => this.openZohoArticle(e, "640662000000667612")}>
                  here
                </a> */}
                <a href="https://help.asinzen.com/identifymyselleraccount" target="_blank">
                  here
                </a>
                .
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Link to="/user/amazon-integration" className="button is-info">
              Complete Amazon Integration
            </Link>
            <button
              className="button is-info"
              onClick={() => {
                this.closePopupMws1();
                this.openPopupMws2();
              }}
            >
              I don't have an Amazon Pro seller account
            </button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPopupMws2} fullWidth maxWidth="sm" onClose={this.closePopupMws2}>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                The asinzen tools depends heavily on Amazon API service to give you the newest and
                most accurate data about an ASIN you are researching. If you do not have access to
                this API, we have to use a different process which we only support under Monthly
                subscription plan.
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closePopupMws2}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.closePopupMws2();
                this.changeInterval("Month");
              }}
            >
              Subscribe to the Monthly plan
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          open={loading}
          fullWidth
          onClose={this.closeLoading}
          PaperProps={{
            style: {
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              boxShadow: "none",
              overflow: "hidden"
            }
          }}
        >
          <CircularProgress />
        </Dialog>
        <Dialog
          open={openErrorDialog}
          fullWidth
          maxWidth="sm"
          onClose={() => this.closeErrorDialog()}
        >
          <DialogTitle disableTypography>
            <strong style={{ color: "#f44336" }}>Oops! You broke the server! Just kidding!</strong>
          </DialogTitle>
          <DialogContent>
            <p style={{ color: "#f44336" }}>
              Sorry, something caused a server error 500. Please click here to contact support to
              let us know that you’ve run into this issue so we can do our best to fix it.
            </p>
          </DialogContent>
          <DialogActions className="buttons are-small">
            <button class="button is-outlined is-sm" onClick={() => this.closeErrorDialog()}>
              Close
            </button>
            <button class="button is-info is-outlined" onClick={() => this.closeErrorDialog(true)}>
              Talk to a Customer Champion
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ListProduct.defaultProps = {
  userState: userInitState,
  planState: planInitState,
  getProductPlanRequest: null,
  getCurrentPluginsRequest: null,
  getCurrentPluginsAddedRequest: null
};

ListProduct.propTypes = {
  userState: PropTypes.shape(userPropTypes),
  planState: PropTypes.shape(planPropTypes),
  getProductPlanRequest: PropTypes.func,
  getCurrentPluginsRequest: PropTypes.func,
  getCurrentPluginsAddedRequest: PropTypes.func
};

const withConnect = connectAutoDispatch(
  ({ userState, orderState, planState, stripeState }) => ({
    userState,
    orderState,
    planState,
    stripeState
  }),
  {
    getProductPlanRequest,
    getCurrentPluginsRequest,
    getCurrentPluginsAddedRequest,
    getUserInfoAppRequest,
    userBuyPluginsRequest,
    stripeGetPricesRequest
  }
);

export default compose(
  withRouter,
  withConnect,
  withSnackbar,
  withStyles(ListProductStyles)
)(ListProduct);
