import { ModuleApp } from "containers";
import ListProduct from "../containers/app/plans";

const Routes = [
  {
    key: "USER_HOME",
    path: "/",
    exact: true,
    component: ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "USER_PLANS",
    path: "/plans",
    exact: true,
    component: ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "USER_LIST_MEMBER",
    path: "/asin-zen-insight/account-management",
    component: ModuleApp.User.ListMemberContainer,
    exact: true,
    componentName: "ListMemberContainer"
  },
  {
    key: "USER_ADD_NEW_MEMBER",
    path: "/user/add-new-member/:extension",
    component: ModuleApp.User.AddNewMemberContainer,
    exact: true,
    componentName: "AddNewMemberContainer"
  },
  {
    key: "USER_EDIT_MEMBER",
    path: "/user/edit-member/:id/:extension",
    component: ModuleApp.User.EditMemberContainer,
    exact: true,
    componentName: "EditMemberContainer"
  },
  {
    key: "USER_CHANGE_PASSWORD",
    path: "/user/change-password",
    component: ModuleApp.User.ChangePasswordContainer,
    exact: true,
    componentName: "ChangePasswordContainer"
  },
  {
    key: "PRODUCT_STRIPE",
    path: "/plans",
    component: ModuleApp.User.ListProduct,
    componentName: "ListProduct"
  },
  {
    key: "CUSTOMER_PORTAL",
    path: "/customer-portal",
    component: ModuleApp.User.CustomerPortal,
    componentName: "CustomerPortal"
  },
  {
    key: "SP_API_STATUS",
    path: "/user/amazon-integration",
    component: ModuleApp.User.SpApiStatus,
    componentName: "SpApiStatus"
  },
  {
    key: "TEAM_HISTORY",
    path: "/user/team-history",
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  }
];

const MemberRoutes = [
  {
    key: "HOME",
    path: "/",
    exact: true,
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  },
  {
    key: "TEAM_HISTORY",
    path: "/user/team-history",
    component: ModuleApp.User.TeamHistory,
    componentName: "TeamHistory"
  }
];

export { Routes, MemberRoutes };
